//火车票列表
import Q from 'q';
import moment from 'moment';

// 火车票订单列表
import trains_buyerOrder_getBuyerOrderList from "@/lib/data-service/trains/trains_buyerOrder_getBuyerOrderList.js";
// 状态枚举
import trains_common_getOrderStatusEnum from "@/lib/data-service/trains/trains_common_getOrderStatusEnum.js";
// // 获取订单支付状态
import trains_order_orderPaymentStatus from "@/lib/data-service/trains/trains_order_orderPaymentStatus.js";
// 取消
import trains_order_applyForCancel from "@/lib/data-service/trains/trains_order_applyForCancel.js";
// 城市插件
import TrainCitySelector from 'yinzhilv-js-sdk/frontend/common-project/component/yinzhilv/train-city-selector/1.1.0/index.vue';




export default {
  components: {
    TrainCitySelector
  },
  data() {
    return {
      status: 0,
      form: {
        currentPage: 1,
        pageSize: 10,
        orderNo: '',
        // passengerName: '',
        trainNo: '',
        gmtStart: '',
        gmtEndTime: '',
        gmtCreateStart: '',
        gmtCreateEnd: '',
        fromStationCode: '',
        toStationCode: '',
        orderStatus: 0,
        orderType: '',
      },
      deparDate: '',
      orderDate: '',
      fromCity: {},
      toCity: {},
      allocation: {
        status: [],
        orderType: [
          { label: '全部', value: '' },
          { label: '预订', value: 1 },
          { label: '抢票', value: 2 },
        ]
      },
      pager: {
        pageSizes: [10, 20, 30, 40, 50],
        total: 0,
      },
      orderList: [],
      timer: null,
      loading: false,
    };
  },
  computed: {

  },
  methods: {
    req_table_status () {
      const _this = this;
      Q.when()
        .then(function () {
          return trains_common_getOrderStatusEnum();
        })
        .then(function (res) {
          _this.allocation.status = res.trainsContantResult;
        })
    },
    req_order_list (params, type) {
      clearInterval(this.timer)
      this.loading = true;
      const _this = this;
      Q.when()
        .then(function () {
          return trains_buyerOrder_getBuyerOrderList(params);
        })
        .then(function (res) {
          _this.orderList = res.pageResult.pageData;
          _this.pager.total = res.pageResult.totalCount;
          if (type) document.documentElement.scrollTop = 0;
          _this.loading = false;
          _this.timer = setInterval(_this.count_down, 1000);
        })
    },
    // 支付状态
    req_order_status (params) {
      return Q.when()
        .then(function () {
          return trains_order_orderPaymentStatus(params);
        })
    },
    req_cancel_order (params) {
      const _this = this;
      Q.when()
        .then(function () {
          return trains_order_applyForCancel(params);
        })
        .then(function (res) {
          _this.$message({
            type: 'success',
            message: '已申请取消订单，请稍候刷新查看!'
          });
          setTimeout(_this.req_order_list(_this.form), 3000)
        })
    },
    count_down () {
      const _this = this;
      this.orderList.forEach(function (item) {
        if (item.orderStatus === 2) {
          const now = moment();
          const diff = moment(item.gmtLastPayment).diff(now,'seconds');
          if (diff >= 0) {
              let minutes = Math.floor(diff / 60);
              minutes = minutes >= 10 ? minutes : '0' + minutes;
              let seconds = diff % 60;
              seconds = seconds >= 10 ? seconds : '0' + seconds;
              item.countdownToPayment = minutes + '分' + seconds + '秒';
              if (diff === 0) {
                _this.req_order_list(_this.form)
              }
              } else if (diff < 0) {
            item.countdownToPayment = '00分00秒';
          }
        }
      })
    },
    search () {
      this.req_order_list(this.form);
    },
    reset () {
      const orderStatus = this.form.orderStatus;
      this.deparDate = '';
      this.orderDate = '';
      this.fromCity = {};
      this.toCity = {};
      this.form = {
        currentPage: 1,
        pageSize: 10,
        orderNo: '',
        trainNo: '',
        gmtStart: '',
        gmtEndTime: '',
        gmtCreateStart: '',
        gmtCreateEnd: '',
        fromStationCode: '',
        toStationCode: '',
        orderStatus,
        orderType: '',
      };

      this.req_order_list(this.form);
    },
    change_status (orderStatus) {
      this.form.orderStatus = orderStatus;
      this.form.currentPage = 1;
      this.req_order_list(this.form);
    },
    return_red (row) {
      if (row.columnIndex === 9) {
        return 'color: red';
      }
    },
    to_detail (row) {
      if (row.orderType === 2) {
        if (row.orderStatus === 4 || row.orderStatus === 5 || row.orderStatus === 6 || row.orderStatus === 7) {
          this.$router.push({
            name: 'distributor-train-back-train-order-detail',
            query: {orderNo: row.orderNo},
          });
        } else {
          this.$router.push({
            name: 'distributor-train-back-train-contend-detail',
            query: {orderNo: row.orderNo},
          });
        }
      } else if (row.orderType === 1) {
        this.$router.push({
          name: 'distributor-train-back-train-order-detail',
          query: {orderNo: row.orderNo},
        });
      }
    },
    to_payment (row) {
      const _this = this;
      const params = {orderNo: row.orderNo, type: row.orderType === 1 ? 'reserve' : 'booking'};
      Q.when()
        .then(function () {
          return _this.req_order_status(params);
        })
        .then(function (res) {
          if (res.ifTimeout === 1) {
            _this.$router.push({
              name: 'distributor-train-front-pay',
              query: params,
            })
          } else if (res.ifTimeout === 2) {
            _this.$message.warning('已超过支付时间，请重新下单');
            _this.req_order_list(_this.form);
          }
        })
    },
    cancel_order (orderNo, thirdOrderNo, orderType) {
      const _this = this;
      this.$confirm('您确认取消订单吗？（一天内最多取消3次）', '取消订单', {
        confirmButtonText: '确定取消',
        cancelButtonText: '我再想想',
        type: 'info'
      }).then(() => {
        Q.when()
          .then(function () {
            if (orderType === 1) {
              return _this.req_order_status({orderNo});
            } else if (orderType === 2) {
              _this.req_cancel_order({orderNo, thirdOrderNo});
            }
          })
          .then(function (res) {
            if (res.ifTimeout === 1) {
              _this.req_cancel_order({orderNo, thirdOrderNo});
            } else if (res.ifTimeout === 2) {
              _this.req_order_list(_this.form);
            }
          })
      }).catch(() => {

      });
    },
    handleSizeChange (pageSiZe) {
      this.form.pageSize = pageSiZe;
      this.form.currentPage = 1;
      this.req_order_list(this.form, 'pageSize');
    },
    handleCurrentChange (currentPage) {
      this.form.currentPage = currentPage;
      this.req_order_list(this.form, 'currentPage');
    }
  },
  watch: {
    fromCity (val) {
      if (val) {
        this.form.fromStationCode = val.stationCode;
      } else {
        this.form.fromStationCode = '';
      }
    },
    toCity (val) {
      if (val) {
        this.form.toStationCode = val.stationCode;
      } else {
        this.form.toStationCode = '';
      }
    },
    deparDate (val) {
      this.form.gmtStart = val[0];
      this.form.gmtEndTime = val[1];
    },
    orderDate (val) {
      this.form.gmtCreateStart = val[0];
      this.form.gmtCreateEnd = val[1];
    },
  },
  mounted() {

  },
  deactivated () {
    clearInterval(this.timer)
  },
  activated () {
    document.documentElement.scrollTop = 0;
    if (this.$route.query.orderStatus) this.form.orderStatus = Number(this.$route.query.orderStatus);
    this.req_table_status();
    this.req_order_list(this.form);
  }
}
